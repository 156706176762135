body {
  margin: 0;
  background-color: #000000;
  font-size: 16px;
}

h1 {
  font-size: 72px;
  font-weight: 800;
}

h2 {
  font-size: 48px;
  font-weight: 600;
}

h3 {
  font-size: 40px;
  font-weight: bold;
}

ol li {
  line-height: 28px;
  font-size: 14px;
}

.title {
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
}

.app-header {
  min-height: 120px;
  font-size: calc(10px + 2vmin);
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 120px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.app-header .app-title {
  font-size: 24px;
  font-weight: 400;
}

.app-logo {
  flex-grow: 1;
}

.app-header nav {
  border: 1px solid #000000;
  /* font-size: 16px; */
}

.app-header nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  column-gap: 1px;
}

.app-header nav a {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 25px;
}

.app-header nav a.selected {
  text-decoration: none;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid transparent;
}

.app-header nav a.selected:hover {
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  box-sizing: border-box;
}

.app-header nav a:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.hero {
  color: #ffffff;
  height: 600px;
  position: relative;
}

.hero .hero-content {
  position: absolute;
}

.hero .hero-image {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}


.hero .hero-image img {
  width: 100%;
}

/* Word Carousel */
#text-slider-container {
  width: 300px;
  /* Adjust as needed */
  height: 50px;
  /* Same height as one item */
  overflow: hidden;
  position: relative;
}

.text-slider {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  animation: slide 10s infinite;
  /* Increase duration to account for more items */
}

.slider-item {
  height: 50px;
  /* Same as the container height */
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 16px;
  line-height: 50px;
  text-transform: uppercase;
}

@keyframes slide {
  0% {
    top: 0;
  }

  12.50% {
    top: -50px;
  }

  25.00% {
    top: -100px;
  }

  37.50% {
    top: -150px;
  }

  50.00% {
    top: -200px;
  }

  62.50% {
    top: -250px;
  }

  85.00% {
    top: -300px;
  }

  97.50% {
    top: -350px;
  }

  100% {
    top: 0;
  }
}

.align-right {
  text-align: right;
}

.black-box {
  background-color: #000000;
}

.white-box {
  background-color: #fff;
}

.hero-margin {
  margin: 60px 120px;
}

.content-margin {
  margin: 20px 120px;
}

.lc-card {
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  border: none;
}

.btn-outline-dark,
.btn-outline-light {
  border-radius: 76px;
}

.aboutus .card {
  background-color: transparent;
  color: #ffffff;
}

.contactus .card {
  background-color: transparent;
  color: #000000;
  border: none;
}

.aboutus .card p {
  color: #616161;
}

.fs-small {
  font-size: 14px;
}

.footer {
  color: #000000;
  font-size: 14px;
}

.footer-left {
  text-align: left;
}

.footer-right {
  text-align: right;
}

.footer a {
  color: #000000;
}

@media (max-width: 767.98px) {
  .align-right {
    text-align: center;
  }

  .center-small {
    text-align: center;
  }

  .app-header {
    padding: 0 30px;
  }

  .hero-margin,
  .content-margin {
    margin-left: 30px;
    margin-right: 30px;
  }

  .app-header .app-title {
    font-size: 16px;
  }

  .app-menu {
    display: none;
  }

  .footer-left,
  .footer-right {
    text-align: center;
  }
}

@media (max-width: 1280px) {
  .app-menu {
    display: none;
  }

  .hero {
    height: auto;
  }

  .hero,
  .hero .hero-image,
  .hero .hero-content {
    position: static;
  }

  .hero .hero-image {
    margin-top: -180px;
  }

  .hero .hero-image img {
    width: 100%;
  }
}